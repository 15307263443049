<template>
  <section class="base-page">
    <section class="page-header base-shadow">
      <h3 class="header-title">
        {{ $route.meta.title }}
        <el-tag
          type="success"
          size="small"
          v-if="currentId === null && currentType === 'CLIENT'"
          >新增模式</el-tag
        >
        <el-tag
          type="success"
          size="small"
          v-if="merchantId !== null && currentType === 'MERCHANTS'"
          >从松露猪采集</el-tag
        >
        <el-tag
          type="warning"
          size="small"
          v-if="currentId !== null && currentType === 'CLIENT'"
          >修改模式</el-tag
        >
      </h3>
    </section>
    <Success
      v-show="resultId !== null && isSubmitSuccess"
      class="base-shadow"
      style="margin: 15px; background-color: #fff"
      @backktolist="onBackToList"
      @handledetail="onShowDetail"
      @handlewrite="writeMore"
    />

    <el-form
      v-if="!isSubmitSuccess"
      :model="form"
      :rules="rules"
      ref="clientForm"
      label-position="top"
      label-width="100px"
      :disabled="submitting"
      v-loading="loading"
      element-loading-text="表单初始化中..."
      class="client-ruleForm"
    >
      <FormBlock name="基本信息">
        <el-row :gutter="30">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户名称" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户品牌" prop="brand">
              <el-input
                v-model="form.brand"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="跟进状态" prop="followUpStatusId">
              <CustomFormItem
                @handleAdd="addFollowUpStatusOption"
                @handleRefresh="getFollowUpStatusOptions"
                :canAdd="checkPermission(['CLIENT_ADMIN'])"
              >
                <el-cascader
                  style="width: 100%"
                  v-model="form.followUpStatusId"
                  :props="{
                    label: 'name',
                    value: 'id',
                    children: 'subs',
                    emitPath: false,
                  }"
                  :options="followUpStatusTreeOptions"
                  :show-all-levels="false"
                  clearable
                ></el-cascader>
              </CustomFormItem>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="负责人" prop="ownerId">
              <BasicSelect
                v-model="form.ownerId"
                :allOptions="ownerOptions"
                :optionNumber="150"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户来源" prop="sourceId">
              <CustomFormItem
                @handleAdd="addSourceOption"
                @handleRefresh="getSourceOptions"
                :canAdd="checkPermission(['CLIENT_ADMIN'])"
              >
                <BasicSelect
                  v-model="form.sourceId"
                  :allOptions="sourceOptions"
                />
              </CustomFormItem>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户阶段" prop="stageId">
              <CustomFormItem
                @handleAdd="addStageOption"
                @handleRefresh="getStageOptions"
                :canAdd="checkPermission(['CLIENT_ADMIN'])"
              >
                <BasicSelect
                  v-model="form.stageId"
                  :allOptions="stageOptions"
                />
              </CustomFormItem>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户类型" prop="typeId">
              <CustomFormItem
                @handleAdd="addTypeOption"
                @handleRefresh="getTypeOptions"
                :canAdd="checkPermission(['CLIENT_ADMIN'])"
              >
                <BasicSelect v-model="form.typeId" :allOptions="typeOptions" />
              </CustomFormItem>
            </el-form-item>
          </el-col>

          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="是否星标" prop="star">
              <el-radio-group v-model="form.star">
                <el-radio :label="true">
                  <i class="el-icon-star-on text-start"></i>
                  星标用户</el-radio
                >
                <el-radio :label="false">
                  <i class="el-icon-star-off"></i>
                  非星标用户</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </FormBlock>
      <FormBlock name="地址信息">
        <el-row :gutter="30">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="地区选择" prop="address.country">
              <keep-alive>
                <BasicCascader
                  v-model="areaValue"
                  @change="handleAreasChange"
                />
              </keep-alive>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="街道" prop="address.street">
              <el-input
                v-model.trim="form.address.street"
                clearable
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </FormBlock>
      <FormBlock name="客户联系人">
        <el-row :gutter="30">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="主要联系人姓名" prop="mainContact.name">
              <el-input
                v-model="form.mainContact.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item
              label="主要联系人电话(格式：138xxxx8000或者07xx-77xxx88)"
              prop="mainContact.phoneNo"
            >
              <el-input
                v-model="form.mainContact.phoneNo"
                placeholder="请输入"
                clearable
              ></el-input>
              <CheckContactPhoneRepeate
                class="el-form-item__error"
                :phone="form.mainContact.phoneNo"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="主要联系人职位" prop="mainContact.position">
              <el-input
                v-model="form.mainContact.position"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="主要联系人邮箱" prop="mainContact.email">
              <el-input
                v-model="form.mainContact.email"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="主要联系人QQ" prop="mainContact.qq">
              <el-input
                v-model="form.mainContact.qq"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="主要联系人微信" prop="mainContact.wx">
              <el-input
                v-model="form.mainContact.wx"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item
              label="主要联系人地址"
              prop="mainContact.addressValue"
            >
              <keep-alive>
                <BasicCascader v-model="form.mainContact.addressValue" />
              </keep-alive>
            </el-form-item>
          </el-col>
        </el-row>
        <p class="more-contatct-title">更多联系人</p>
        <el-card
          class="box-card"
          v-for="(contact, index) in form.contacts"
          :key="contact.key"
          style="margin: 15px 0"
        >
          <div slot="header" class="clearfix">
            <span>联系人 {{ index + 1 }}</span>
          </div>
          <el-row :gutter="30">
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="联系人姓名"
                :prop="'contacts.' + index + '.name'"
                :rules="{
                  required: true,
                  message: '联系人姓名不能为空',
                  trigger: ['blur'],
                }"
              >
                <el-input
                  v-model="contact.name"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="联系人电话(格式：138xxxx8000或者07xx-77xxx88)"
                :prop="'contacts.' + index + '.phoneNo'"
                :rules="{
                  required: true,
                  validator: checkPhoneNumber,
                  trigger: ['change', 'blur'],
                }"
              >
                <el-input
                  v-model="contact.phoneNo"
                  placeholder="请输入"
                  clearable
                ></el-input>
                <CheckContactPhoneRepeate
                  class="el-form-item__error"
                  :phone="contact.phoneNo"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="联系人职位"
                :prop="'contacts.' + index + '.position'"
              >
                <el-input
                  v-model="contact.position"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="联系人邮箱"
                :prop="'contacts.' + index + '.email'"
              >
                <el-input
                  v-model="contact.email"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="联系人QQ"
                :prop="'contacts.' + index + '.qq'"
              >
                <el-input
                  v-model="contact.qq"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="联系人微信"
                :prop="'contacts.' + index + '.wx'"
              >
                <el-input
                  v-model="contact.wx"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="联系人地址"
                :prop="'contacts.' + index + '.addressValue'"
              >
                <!-- :rules="{
                  required: true,
                  message: '请填写联系人地址',
                  trigger: ['change'],
                }" -->
                <keep-alive>
                  <BasicCascader v-model="contact.addressValue" />
                </keep-alive>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="操作" prop="position">
                <el-button type="danger" @click="removeContact(index)"
                  >删除</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <div
          v-show="!submitting"
          class="add-more-contacter"
          title="点击添加联系人"
          @click="addContact"
        >
          <i class="el-icon-plus"></i>
        </div>
      </FormBlock>
      <FormBlock name="客户标签">
        <template #control>
          <el-button
            size="small"
            @click="selectTagDialog = true"
            class="float-r"
            >选择标签</el-button
          >
        </template>
        <el-tag
          class="tag"
          v-for="tag in tags"
          :key="tag.id"
          size="medium"
          closable
          type="info"
          @close="handleCloseTag(tag)"
        >
          <i class="el-icon-price-tag"></i>
          {{ tag.name }}
        </el-tag>
      </FormBlock>
      <FormBlock name="备注">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入"
          clearable
          v-model="form.remark"
        >
        </el-input>
      </FormBlock>
      <FormBlock name="提交操作">
        <el-row :gutter="30">
          <el-col :span="16" :offset="8" style="text-align: right">
            <el-button
              v-if="currentId === null"
              type="primary"
              @click="submitForm('clientForm')"
              :loading="submitting"
              >立即创建</el-button
            >
            <el-button
              v-if="currentId !== null && currentType === 'CLIENT'"
              type="warning"
              @click="editForm('clientForm')"
              :loading="submitting"
              >提交修改</el-button
            >
            <el-button
              @click="resetForm('clientForm')"
              v-if="currentId === null"
              :disabled="submitting"
              >重置</el-button
            >
          </el-col>
        </el-row>
      </FormBlock>
    </el-form>
    <el-dialog
      title="新增跟进状态"
      :visible.sync="addFollowUpStatusDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <FollowUpStatusForm
        @cancel="addFollowUpStatusDialog = false"
        @success="onAddFollowUpStatusSuccess"
      />
    </el-dialog>
    <el-dialog
      title="新增联系人"
      :visible.sync="addContactDialog"
      append-to-body
      :close-on-click-modal="false"
      width="700px"
    >
      <ContactsForm
        :params="contactParams"
        @cancel="addContactDialog = false"
        @success="onAddContactsSuccess"
      />
    </el-dialog>
    <el-dialog
      title="新增类型"
      :visible.sync="addTypeDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <TypeForm
        @cancel="addTypeDialog = false"
        @success="onAddTypeFormSuccess"
      />
    </el-dialog>
    <el-dialog
      title="新增阶段"
      :visible.sync="addStageDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <StagesForm
        @cancel="addStageDialog = false"
        @success="onAddStageFormSuccess"
      />
    </el-dialog>
    <el-dialog
      title="新增来源"
      :visible.sync="addSourceDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <SourcesForm
        @cancel="addSourceDialog = false"
        @success="onAddSourceFormSuccess"
      />
    </el-dialog>
    <el-dialog
      title="选择标签"
      :visible.sync="selectTagDialog"
      append-to-body
      :close-on-click-modal="false"
      width="800px"
    >
      <TagSelectForm
        :ids="form.tagIds"
        @cancel="onSetTagCancel"
        @confirm="onSetTagSuccess"
      />
    </el-dialog>
  </section>
</template>

<script>
import FormBlock from "@/components/FormBlock";
import { createUniqueString } from "@/utils/common";
import BasicCascader from "@/components/BasicCascader";
import CheckContactPhoneRepeate from "@/components/CheckContactPhoneRepeate";
import clientForm from "./mixins/clientForm";
import clientFormMerchant from "./mixins/clientFormMerchant";
import { PostClient, GetClientById, PutClientById } from "./api";
import { ShowApiError } from "@/request/error";
import CustomFormItem from "@/components/CustomFormItem";
import FollowUpStatusForm from "@/views/client/clientFollowUpsStatuses/components/Form";
import ContactsForm from "@/views/client/contacts/components/Form";
import TypeForm from "@/views/client/clientTypes/components/Form";
import StagesForm from "@/views/client/clientStages/components/Form";
import SourcesForm from "@/views/client/clientSources/components/Form";
import TagSelectForm from "@/views/client/clientTags/components/TagSelectForm";
import BasicSelect from "@/components/BasicSelect";
import Success from "@/components/Success";
import { checkPermission } from "@/utils/auth";
import { onRefreshCurrentPage } from "@/utils/auth";
import { checkPhoneNumber } from "@/utils/validate";
export default {
  name: "ClientsForm",
  components: {
    FormBlock,
    BasicCascader,
    CustomFormItem,
    FollowUpStatusForm,
    ContactsForm,
    TypeForm,
    StagesForm,
    SourcesForm,
    TagSelectForm,
    Success,
    BasicSelect,
    CheckContactPhoneRepeate,
  },
  mixins: [clientForm, clientFormMerchant],
  data() {
    return {
      loading: false,
      submitting: false,
      areaValue: [],
      currentId: null,
      currentType: "CLIENT",
      isSubmitSuccess: false,
      resultId: null,
      form: {
        address: {
          city: "",
          country: "",
          province: "",
          region: "",
          street: "",
        },
        brand: "",
        contacts: [],
        followUpStatusId: null,
        mainContact: {
          addressValue: [],
          address: {
            city: "",
            country: "",
            province: "",
            region: "",
            street: "",
          },
          email: "",
          name: "",
          phoneNo: "",
          position: "",
          qq: "",
          wx: "",
        },
        name: "",
        ownerId: null,
        sourceId: null,
        stageId: null,
        star: false,
        tagIds: [],
        typeId: null,
        remark: "",
      },
      tags: [],
    };
  },
  watch: {
    $route: {
      handler() {
        this.initFormSelection();
        this.onCheckFormModel();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    checkPhoneNumber,
    checkPermission,
    createUniqueString,
    onCheckFormModel() {
      if (this.$route.name === "ClientsForm") {
        if (this.$route.params.id && this.$route.params.id !== null) {
          this.onCheckClientEditData();
          this.onCheckMerchantData();
        } else {
          if (this.$route.params.initForm) {
            this.resetForm("clientForm");
            this.currentId = null;
            this.merchantId = null;
          } else {
            this.currentId = null;
            this.merchantId = null;
          }
        }
      }
      this.isSubmitSuccess = false;
    },
    onCheckClientEditData() {
      if (this.$route.params.type && this.$route.params.type == "CLIENT") {
        this.currentId = this.$route.params.id;
        this.currentType = this.$route.params.type;
        this.getFormData(this.currentId);
      }
    },
    getSubmitAddressData(addressValue = []) {
      if (addressValue instanceof Array && addressValue.length > 0) {
        if (addressValue.length > 3) {
          return {
            country: addressValue[0] ? addressValue[0] : "",
            province: addressValue[1] ? addressValue[1] : "",
            city: addressValue[2] ? addressValue[2] : "",
            region: addressValue[3] ? addressValue[3] : "",
            street: "",
          };
        } else {
          return {
            country: addressValue[0] ? addressValue[0] : "",
            province: addressValue[1] ? addressValue[1] : "",
            city: addressValue[2] ? addressValue[2] : "",
            region: "",
            street: "",
          };
        }
      } else {
        return {
          country: "",
          province: "",
          city: "",
          region: "",
          street: "",
        };
      }
    },
    formatFormData() {
      let formParams = Object.assign({}, this.form);
      formParams.mainContact.address = this.getSubmitAddressData(
        formParams.mainContact.addressValue
      );
      delete formParams.mainContact.addressValue;
      if (formParams.contacts.length > 0) {
        formParams.contacts = formParams.contacts.map((item) => {
          let _item = Object.assign({}, item);
          _item.address = this.getSubmitAddressData(_item.addressValue);
          delete _item.addressValue;
          return _item;
        });
      }
      return formParams;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;

          PostClient(this.formatFormData())
            .then((res) => {
              this.resultId = res.data.id;
              this.submitting = false;
              this.initForm();
              this.isSubmitSuccess = true;
              this.$store.dispatch("basicOption/initClientOption");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("新增客户失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutClientById(this.currentId, this.formatFormData())
            .then(() => {
              this.$message.success("修改成功");
              this.submitting = false;
              this.resetForm(formName);
              this.getFormData(this.currentId);
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("修改客户失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData(id = "") {
      this.loading = true;
      GetClientById(id)
        .then((res) => {
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              this.form[key] = res.data[key];
            }
          }
          this.tags = res.data.tags;
          this.form.tagIds =
            res.data.tags.length > 0
              ? res.data.tags.map((item) => item.id)
              : [];
          if (this.form.mainContact === null) {
            this.form.mainContact = {
              addressValue: [],
              address: {
                city: "",
                country: "",
                province: "",
                region: "",
                street: "",
              },
              email: "",
              name: "",
              phoneNo: "",
              position: "",
              qq: "",
              wx: "",
            };
          } else {
            this.form.mainContact = {
              ...this.form.mainContact,
              addressValue: this.onReviewFormAddress(
                this.form.mainContact.address
              ),
            };
          }

          if (this.form.contacts.length > 0) {
            this.form.contacts = this.form.contacts.map((item) => {
              return {
                ...item,
                addressValue: this.onReviewFormAddress(item.address),
              };
            });
          }
          if (this.form.address !== null) {
            this.areaValue = this.onReviewFormAddress(this.form.address);
          } else {
            this.areaValue = [];
            this.form.address = {
              city: "",
              country: "",
              province: "",
              region: "",
              street: "",
            };
          }

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单数据失败", err);
        });
    },
    onReviewFormAddress(address = {}) {
      if (address === null || Object.keys(address).length <= 0) {
        return [];
      }
      if (address.region === "") {
        return [address.country, address.province, address.city];
      } else {
        return [
          address.country,
          address.province,
          address.city,
          address.region,
        ];
      }
    },
    resetForm(formName) {
      this.initForm();
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    addContact() {
      this.form.contacts.push({
        addressValue: [],
        address: {
          city: "",
          country: "",
          province: "",
          region: "",
          street: "",
        },
        email: "",
        name: "",
        phoneNo: "",
        position: "",
        qq: "",
        wx: "",
        key: createUniqueString(),
      });
    },
    removeContact(index) {
      this.form.contacts.splice(index, 1);
    },
    initForm() {
      this.areaValue = [];
      this.tags = [];
      this.contactParams = {};
      this.form = {
        address: {
          city: "",
          country: "",
          province: "",
          region: "",
          street: "",
        },
        brand: "",
        contacts: [],
        followUpStatusId: null,
        mainContact: {
          addressValue: [],
          address: {
            city: "",
            country: "",
            province: "",
            region: "",
            street: "",
          },
          email: "",
          name: "",
          phoneNo: "",
          position: "",
          qq: "",
          wx: "",
        },
        name: "",
        ownerId: null,
        sourceId: null,
        stageId: null,
        star: false,
        tagIds: [],
        typeId: null,
        remark: "",
      };
    },
    handleAreasChange(val) {
      this.form.address.country = val[0] ? val[0] : "";
      this.form.address.province = val[1] ? val[1] : "";
      this.form.address.city = val[2] ? val[2] : "";
      this.form.address.region = val[3] ? val[3] : "";
    },
    handleCloseTag(tag) {
      this.tags = this.tags.filter((item) => item.id !== tag.id);
      this.form.tagIds = this.form.tagIds.filter((tagId) => tagId !== tag.id);
    },
    onBackToList() {
      this.resultId = null;
      this.isSubmitSuccess = false;
      this.$router.push("/clients/list");
    },
    onShowDetail() {
      if (this.resultId === "") {
        return;
      }
      this.isSubmitSuccess = false;
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: this.resultId,
        },
      });
      this.resultId = null;
    },

    writeMore() {
      this.resultId = null;
      this.isSubmitSuccess = false;
      onRefreshCurrentPage("/clients/form/CLIENT");
    },
  },
};
</script>

<style lang="scss" scoped>
.client-ruleForm {
  ::v-deep {
    .el-form-item__label {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .el-form-item__content {
      height: 41px;
    }
  }
}
.base-select {
  box-sizing: border-box;
  width: 100%;
}
.more-contatct-title {
  box-sizing: border-box;
  padding: 10px 0;
  text-align: left;
  font-size: 15px;
  color: #989898;
}
.add-more-contacter {
  width: 100%;
  box-sizing: border-box;
  border: 1px dashed #ddd;
  border-radius: 3px;
  padding: 5px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  color: #989898;
  &:hover {
    background-color: #fcf8f8;
    color: #000;
  }
}
.tag {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>