<template>
  <section class="tag-select-wrap">
    <div class="tag-form-wrap" :class="[showTagDialog ? 'active' : '']">
      <Form
        :id="null"
        @cancel="onFormCancel"
        @success="onFormSuccess"
        style="width: 500px"
      />
    </div>
    <el-tabs tab-position="left" class="tag-tabs">
      <el-tab-pane label="全部">
        <template v-for="tagGroup in treeData">
          <TagItems
            :key="'all' + tagGroup.id"
            :selectIds="selectIds"
            :tagGroup="tagGroup"
            @change="onCheckBoxChange"
          />
        </template>
      </el-tab-pane>
      <el-tab-pane
        :label="tagGroup.name"
        v-for="tagGroup in treeData"
        :key="tagGroup.id"
      >
        <TagItems
          :selectIds="selectIds"
          :tagGroup="tagGroup"
          @change="onCheckBoxChange"
        />
      </el-tab-pane>
    </el-tabs>
    <section class="control">
      <el-button
        @click="onAddTag"
        class="float-l"
        v-if="checkPermission(['CLIENT_ADMIN', 'CLIENT_USER'])"
        >添加标签</el-button
      >
      <el-button @click="onCancel">关闭</el-button>
      <el-button type="primary" @click="onConfirm">确认</el-button>
    </section>
  </section>
</template>

<script>
import { GetClientTagsTree } from "../api";
import { ShowApiError } from "@/request/error";
import Form from "./Form";
import TagItems from "./TagItems";
import { checkPermission } from "@/utils/auth";
export default {
  components: {
    Form,
    TagItems,
  },
  props: {
    ids: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      showTagDialog: false,
      treeData: [],
      loading: false,
      selectIds: [],
      selectItems: [],
    };
  },
  created() {
    this.getTreeData();
  },
  watch: {
    ids: {
      handler(val) {
        if (val && val.length > 0) {
          this.selectIds = val;
          this.initItems();
        } else {
          this.selectIds = [];
          this.selectItems = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    checkPermission,
    initItems() {
      if (this.treeData.length > 0) {
        this.selectItems = []
        this.treeData.forEach((item) => {
          if (item.subs.length > 0) {
            item.subs.forEach((tag) => {
              if (this.selectIds.includes(tag.id)) {
                this.selectItems.push(tag);
              }
            });
          }
        });
      }
    },
    getTreeData() {
      this.loading = true;
      GetClientTagsTree()
        .then((res) => {
          this.loading = false;
          this.treeData = res.data;
          this.initItems();
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取标签数据异常", err);
        });
    },
    onCheckBoxChange(id, item) {
      if (this.selectIds.includes(id)) {
        this.selectIds = this.selectIds.filter((tagId) => tagId !== id);
        this.selectItems = this.selectItems.filter((item) => item.id !== id);
      } else {
        this.selectIds.push(id);
        this.selectItems.push(item);
      }
    },
    onAddTag() {
      this.showTagDialog = true;
    },
    onCancel() {
      this.$emit("cancel");
    },
    onConfirm() {
      this.$emit("confirm", this.selectIds, this.selectItems);
    },
    onFormCancel() {
      this.showTagDialog = false;
    },
    onFormSuccess() {
      this.getTreeData();
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-select-wrap {
  box-sizing: border-box;
  width: 100%;
  min-height: 400px;
  position: relative;
  overflow: hidden;
  .tag-form-wrap {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 22;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-100%);
    transition: all 0.3s linear;
    &.active {
      transform: translateY(0);
    }
  }
  .tag-tabs {
    height: 400px;
    ::v-deep {
      .el-tabs__content {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
  .control {
    box-sizing: border-box;
    padding-top: 30px;
    text-align: right;
  }
}
</style>