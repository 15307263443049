import { render, staticRenderFns } from "./ClientForm.vue?vue&type=template&id=02ccabd6&scoped=true&"
import script from "./ClientForm.vue?vue&type=script&lang=js&"
export * from "./ClientForm.vue?vue&type=script&lang=js&"
import style0 from "./ClientForm.vue?vue&type=style&index=0&id=02ccabd6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02ccabd6",
  null
  
)

export default component.exports